// var stickyHeader = false;
// var transformHomepageDonationAmountImages = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var homeQuickGivingPreFooter = false;
var countUpStats = true;
var countUpStatsDuration = 3000;

// Client embedded magazine as iframe.
// This adds a div around the iframe to display it betterer!
$(".PostCategory_magazine .contentBlockWrapper .postContent iframe").wrap("<div class='iframeBox'></div>");
